import dayjs from "dayjs";

const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});

const formatCurrency = (value) => {
    return currencyFormatter.format(value);
}

const formatDate = (iso, format) => {
    const d = dayjs(iso);
    return d.format(format);
}

const setWindowTitle = (title) => {
    window.document.title = title;
}

const toArray = (arr) => {
    return Array.isArray(arr) ? arr : [];
}
const sleep = async(ms) => {
    return new Promise((resolve)=>{ setTimeout(()=>{ resolve(); }, ms || 2000); });
};

const setItem = (key, value) => { window.localStorage.setItem(key, JSON.stringify(value)); }
const getItem = (key) => { const value = window.localStorage.getItem(key); return JSON.parse(value); }
const clearItem = (key) => { window.localStorage.clearItem(key); }

const toBoolean = (value, defaultValue) => {
    defaultValue = defaultValue === true ? true : false;
    if(value===true) {
        return true;
    }
    if(value===false) {
        return false;
    }
    return defaultValue;
}

const renderValue = (value) => {

    switch (typeof value) {
        case 'boolean':
            return value === true ? 'true' : 'false';
        case 'object':
            if(typeof value?.$$typeof === 'symbol') {
                return value;
            }
            return value ? JSON.stringify(value) : '';
        case 'string':
            return value || '';
        default:
            return value;
    }
}

const toObject = (obj) => toJson(obj);
const toJson = (obj) => {
    if(obj && typeof obj === 'object') {
        return obj;
    }

    try {
        obj = JSON.parse(obj);
    }
    catch(error) {
        obj = {};
    }

    if(!obj) {
        obj = {};
    }

    return obj;
}

const toNumber = (value) => {
    return Number(String(value).replace(/[^\d.-]/g, ''));
}

const isNullish = (value) => {
    return value === undefined || value === null;
}

const isObject = (a) => {
    return (!!a) && (a.constructor === Object);
};

const padZero = (str) => {
    str = String(str);
    return str.length<2 ? `0${str}` : str;
}

const camelise = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

const textToTitle = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const getOpts = (opts) => {
    opts = toArray(opts);
    const data = [];
    for(const opt of opts) {
        data.push(getOpt(opt));
    }
    return data;
}

const getOpt = (opt) => {
    if(typeof opt === 'string') {
        opt = {value:opt, text: textToTitle(opt)};
    }
    return opt;
}

const getFields = (fields) => {
    if(!Array.isArray(fields)) {
        fields = [];
    }
    
    const data = [];
    for(const field of fields) {
        data.push(getField(field));
    }
    return data;
}

const getField = (field) => {
    if(typeof field ==='string') {
        field = {type:'text', label: textToTitle(field), key:field};
    }
    if(!field.type) {
        field.type = 'text';
    }
    if(field.type === 'select') {
        field.opts = Array.isArray(field.opts) ? field.opts : [];
    }

    return field;
}

const copyObject = (obj) => {
    obj = toObject(obj);
    return JSON.parse(JSON.stringify(obj));
}

const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
}

const downloadJson = (json, filename) => {
    if(!json || (Array.isArray(json) && json.length===0)) {
        window.alert('No data found to download');
        return;
    }
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
    const node = document.createElement('a');
    node.setAttribute("href", dataStr);
    node.setAttribute("download", (filename || 'json-download') + ".json");
    document.body.appendChild(node); // required for firefox
    node.click();
    node.remove();
}

const downloadCsv = (data, filename) => {
    if(!Array.isArray(data)) { window.alert('Data can not be downloaded'); return; }
    if(isObject(data[0])) {
        const rows = [];
        const keys = Object.keys(data[0]);
        rows.push(keys);
        for(const d of data) {
            rows.push(Object.values(d));
        }
        data = rows;
    }

    const csv = data.map(row =>
      row
      .map(String)  // convert every value to String
      .map(v => v.replaceAll('"', '""'))  // escape double colons
      .map(v => `"${v}"`)  // quote it
      .join(',')  // comma-separated
    ).join('\r\n');  // rows starting on new lines

    const node = document.createElement('a');
    const blob = new Blob([csv],{type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    node.href = url;
    node.setAttribute('download', (filename || 'csv-download') + ".csv");
    node.click();
    node.remove();
}

const getHostname = () => {
    return String(window.location.host).split(':')[0];
}

const getKeywords = (obj, excludeKeys) => {
    excludeKeys = excludeKeys || [];
    if(excludeKeys) {
        obj = copyObject(obj);
    }
    for(const key of excludeKeys) {
        delete obj[key];
    }
    return Object.values(obj).join(' ').toLowerCase();
}

const isLocalhost = () => {
    const host = getHostname();
    return host.startsWith('localhost') || host.includes('192.168.');
}

const uuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const openHtml = (html) => {
    const newWindow = window.open();
    newWindow.document.write(html);
}

const openBase64 = (base64) => {
    const blob = base64ToBlob( base64, 'application/pdf' );
    const url = URL.createObjectURL( blob );
    const pdfWindow = window.open();
    pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
}

const base64ToBlob = ( base64, type = "application/octet-stream" ) => {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    return new Blob( [ arr ], { type: type } );
}

const loadedScripts = {};
const loadScript = (url) => {
  if (!loadedScripts[url]) {
    var s = document.createElement('script');
    s.src = url;
    document.head.appendChild(s);
    loadedScripts[url] = true;
  }
}

const utils = {
    loadScript,
    openHtml,
    openBase64,
    getKeywords,
    uuid,
    setItem,
    getItem,
    clearItem,
    copyObject,
    getFields,
    getField,
    textToTitle,
    renderValue,
    toArray,
    camelise,
    padZero,
    isObject,
    isNullish,
    toNumber,
    toJson,
    toObject,
    toBoolean,
    sleep,
    setWindowTitle,
    getOpts,
    getOpt,
    copyToClipboard,
    formatDate,
    downloadJson,
    downloadCsv,
    getHostname,
    isLocalhost,
    formatCurrency,
}

export default utils;