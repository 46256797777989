import { useSelector, useDispatch } from 'react-redux';
import * as store from '../core/store';

export default function useData() {
    const dispatch = useDispatch();
    const data = useSelector(state=>state.data);

    const setData = (d) => {
        dispatch(store.setData(d));
    }

    const resetData = () => {
        dispatch(store.setData({}));
    }

    return {
        data,
        setData,
        resetData,
    };
}