
import { toast, useToast } from 'react-toastify';


export default function useToastHook() {

    const addToastHook = (message, params) => toast(message, params);

    const apiError = (error) => {
        // console.log(error);
        let e = 'API Error';
        if(error?.response?.data?.error) { e = error?.response?.data?.error; }
        else if(error?.message) { e = error.message; }
        else if(error?.error) { e = error.error; }
        else { e = error; }

        addToast(e, {type:'error'});
    }

    const addToast = (input, params, delay) => {
        if(delay !== undefined) {
            delay = Number(delay);
            setTimeout(()=>{ addToast(input, params); }, delay);
            return;
        }

        if(typeof input === 'string') {
            input = {message:input};
        }

        const {title, message} = input;

        params = params || {};
        let body;
        if(!title && !message) {
            body = JSON.stringify(input, null, 2);
        }
        else if(title && message) {
            body = (<>
                <h5>{title}</h5>
                <hr />
                <div style={{maxHeight:'100px', overflow:'auto'}}>{message}</div>
            </>);
        }
        else {
            body = title ? title : message;
        }

        addToastHook(body, {
            type:'info', 
            position: 'bottom-right',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            className: 'avione-toast',
            ...params,
        });
    }

    return {
        addToast,
        apiError,
    };

}