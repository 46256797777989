import { useEffect, useState } from 'react';
import { Row, Card, Tabs, Tab, Nav, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';

import useToast from '../hooks/useToast';
import useMe from '../hooks/useMe';

import Table from '../comps/Table';
import Input from '../comps/Input';
import Btn from '../comps/Btn';
import TabsPanel from '../comps/TabsPanel';

import utils from '../core/utils';
import itp from '../core/itp';

import dayjs from 'dayjs';

import { FabricLayers } from '../views/itp/FabricLayers';

const languageFields = utils.getFields(itp.languages);

export const ItpEdit = ({paramKey, apiUrl, fields, label, labels, url, getEditingText, processData, onBack, iconName, languages, isFabric, canAdd, canRemove}) => {

    const navigate = useNavigate();
    const { getClient, isAdmin } = useMe();
    const client = getClient();

    if(!getEditingText) {
        getEditingText = () => { return label; }
    }

    if(!processData) {
        processData = (data) => { return data; }
    }

    canAdd = utils.toBoolean(canAdd, false);
    canRemove = utils.toBoolean(canRemove, false);
    url = url || '/labels';
    onBack = onBack || function() { navigate(url); };
    // console.log({isAdmin:isAdmin()});

    const [working, setWorking] = useState(false);
    const [data, setData] = useState({});
    const { addToast, apiError } = useToast();
    const params = useParams();
    const dataId = params[paramKey];
    const isAdd = dataId === '0';

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - ${labels}`);
        if(canAdd && isAdd) {
            
        }
        else {
            await loadData();
        }
    }

    const loadData = async() => {
        setWorking(true);

        // const apiPath = isFabric ?  `${apiUrl}/${dataId}?clientId=${client.id}` : `${apiUrl}/${dataId}`;
        
        try {
            const data = await API.get('api', `${apiUrl}/${dataId}?clientId=${client.id}`);
            
            const dateKeys = ['createdAt', 'updatedAt'];
            for(const dateKey of dateKeys) {
                const date = dayjs(data[dateKey]);
                data[dateKey+'Text'] = date.format(itp.settings.dateTimeFormat)+' ('+date.fromNow()+')';
            }
            setData(processData(data));
        }
        catch(error) {
            apiError(error);
        }

        setWorking(false);
    }

    const onSave = async() => {

        setWorking(true);

        // const apiPath = isFabric ?  `${apiUrl}/${dataId}?clientId=${client.id}` : `${apiUrl}/${dataId}`;

        if(isAdd && canAdd) {
            try {
                const newData = await API.post('api', `${apiUrl}?clientId=${client.id}`, {body:data});
                navigate(itp.getRouterPath(`${url}/${newData.id}`));
                addToast(`Saved ${label}`);
            }
            catch(error) {
                console.dir(error);
                apiError(error);
            }
        }
        else {
            try {
                await API.put('api', `${apiUrl}/${dataId}?clientId=${client.id}`, {body:data});
                addToast(`Saved ${label}`);
            }
            catch(error) {
                console.dir(error);
                apiError(error);
            }
        }
        setWorking(false);
    }

    const onRemove = async() => {
        if(!window.confirm(`Remove ${label}?`)) { return; }
        setWorking(true);

        try {
            await API.del('api', `${apiUrl}/${dataId}?clientId=${client.id}`);
            navigate(itp.getRouterPath(url));
            addToast(`Removed ${label}`);
        }
        catch(error) {
            console.dir(error);
            apiError(error);
        }
        setWorking(false);
    }

    const onDownload = () => {
        utils.downloadJson(data, `${label}-${dataId}`);
    }

    useEffect(()=>{ init(); }, []);

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    const header = (
        <>
            {/* <Btn onClick={handleOnBack} text={`Back to ${labels}`} variant={'secondary'} iconName="back" />{' '}
            <Btn onClick={onSave} text={`Save ${label}`} variant={'success'} working={working} disabled={working} iconName="save" /> */}
        </>
    );

    const tabs = [
        {
            title: getEditingText(data) || label,
            component:(<Input.Fields fields={fields} data={data} onChange={(key, value)=>{ setData({...data, [key]:value}); }} />),
        },
    ];

    if(languages) {
        tabs.push({
            title: 'Languages',
            iconName: 'Translate',
            component:(<Input.Fields fields={languageFields} data={data} onChange={(key, value)=>{ setData({...data, [key]:value}); }} />),
        });
    }

    if(isFabric && isAdmin()) {
        tabs.push({
            title: 'Layers',
            iconName: 'Stack',
            component:(<FabricLayers fabric={data} onChange={(layers)=>{ setData({...data, layers}); }} />),
        });
    }

    return (
        <div className={classes.join(' ')}>
            <Row>
            <Col sm="9">
                <TabsPanel tabs={tabs} header={header} />
            </Col>
            <Col sm="3">
                <Card>
                    <Card.Header>
                        <div className='float-end'>
                            <Btn text={`Back to ${labels}`} iconName={'back'} onClick={onBack} />{' '}
                            {canRemove && <><Btn text={`Remove ${label}`} variant={'danger'} iconName={'close'} onClick={onRemove} />{' '}</>}
                            <Btn text="Save" iconName={'save'} onClick={onSave} variant={'success'} />

                        </div>
                    </Card.Header>
                    <Card.Body>
                        <table>
                        <tbody className='table'>
                        {isAdmin() && data['id'] && <tr>
                            <td><b>ID</b></td><td>{data['id']}</td>
                        </tr>}
                        {data['createdAtText'] && (<tr>
                            <td><b>Created At</b></td><td>{data['createdAtText']}</td>
                        </tr>)}
                        {data['updatedAtText'] && (<tr>
                            <td><b>Updated At</b></td><td>{data['updatedAtText']}</td>
                        </tr>)}
                        </tbody>
                        </table>
                        <br />
                        <Btn text="Download as JSON" onClick={onDownload} iconName="download" variant="outline" />
                        
                        {isAdmin() && data['id'] && <>
                        <hr />
                        <Btn text="AWS DynamoDB" onClick={()=>{ window.open(`https://eu-west-2.console.aws.amazon.com/dynamodbv2/home?region=eu-west-2#edit-item?itemMode=2&pk=${data['id']}&route=ROUTE_ITEM_EXPLORER&sk=&table=itp-data-dev`); }} />
                        </>}
                    </Card.Body>
                </Card>
            </Col>
            </Row>
        </div>
    )
}

export const ItpList = ({apiUrl, fields, labels, onAction, processData, iconName, onAdd}) => {

    if(!processData) {
        processData = (data) => { return data; }
    }

    const [working, setWorking] = useState(false);
    const [data, setData] = useState([]);
    const { addToast } = useToast();

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - ${labels}`);
        await loadData();
    }

    const loadData = async() => {
        setWorking(true);

        try {
            const data = await API.get('api', apiUrl);
            for(const d of data) {
                d.keywords = Object.values(d).join(' ').toLowerCase();
            }
            setData(processData(data));
        }
        catch(error) {
            addToast(error);
        }

        setWorking(false);
    }

    useEffect(()=>{ init(); }, []);

    // const classes = ['fade-in'];
    // if(working) {
    //     classes.push('working');
    // }

    const tabs = [
        {
            component:(
                <>
                <div className="float-end">{onAdd && <Btn text="Add" onClick={onAdd} />}</div>
                <Table data={data} fields={fields} onAction={onAction} actionText='Edit' placeholder={`Filter ${labels}`} 
                    header={<Btn text="Reload" iconName={'reload'} onClick={loadData} />} working={working} download={true} />
                </>), 
            title:labels, iconName,
        },
    ];

    return (
        <div className="fade-in">
            <TabsPanel tabs={tabs} header={''} />
        </div>
    );
}