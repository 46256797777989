import { useEffect } from 'react';
import { API } from 'aws-amplify';

import useData from './useData';
import useHub from './useHub';

import utils from '../core/utils';
import itp from '../core/itp';

const getClientId = () => {
    return itp.getStoredClientId();
}

const setClientId = (clientId) => {
    itp.setStoredClientId(clientId);
}

export default function useMe() {

    const hub = useHub();
    const { data, setData, } = useData();
    
    const dataKey = 'me';

    const me = data[dataKey];

    const loadMe = async() => {
        const clientId = getClientId();
        let path = '/me';
        if(clientId) {
            path += `?clientId=${clientId}`;
        }
        return await API.get('api', path);
    }

    const getMe = async(reload) => {
        if(!me || reload) {
            const d = await loadMe();
            setData({[dataKey]:d});
            return d;
        }

        return me;
    }

    const isAdmin = () => {
        // me = me || data[dataKey];
        const permissions = utils.toArray(me?.permissions);
        return permissions.includes('admin');
    }

    const isManager = () => {
        // me = me || data[dataKey];
        const permissions = utils.toArray(me?.permissions);
        return permissions.includes('manager');
    }

    const isDev = () => {
        return me?.isDev;
    }

    const getClient = () => {
        const clientId = getClientId();
        const clients = getClients();
        const client = clients.find(c=>c.id===clientId);
        return client || me?.client;
    }

    const getClients = () => {
        return utils.toArray(me?.clients);
    }

    const setClient = (clientId) => {
        setClientId(clientId);
    }

    const resetMe = () => {
        setData({[dataKey]:null});
    }

    useEffect(()=>{  
        if(hub.channel === 'auth' && hub.event === 'signOut') {
            resetMe();
        }
    }, [hub.channel, hub.event]);

    return {
        me,
        loadMe,
        getMe,
        isAdmin,
        isManager,
        isDev,
        getClient,
        getClients,
        setClient,
        resetMe,
    };

}