import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import utils from "../core/utils";

import { Editor as Tinymce } from '@tinymce/tinymce-react';

import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';

const Fields = ({fields, data, onChange, ...props}) => {
    fields = utils.toArray(fields);
    data = utils.toObject(data);

    const handleOnChange = (key, value) => {
        if(onChange) {
            onChange(key, value);
        }
    }

    return fields.map((field, i)=>(
        <Input.Group key={i} {...field} value={utils.isNullish(data[field.key]) ? '' : data[field.key]} onChange={(value)=>{ handleOnChange(field.key, value); }} {...props} />
    ));
}

const Group = ({label, type, info, ...props}) => {
    
    type = type || 'text';

    // const handleOnChange = (e) => {
    //     if(onChange) {
    //         onChange(e.target.value);
    //     }
    // }

    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            {type==='text' && Text(props)}
            {type==='password' && Password(props)}
            {type==='select' && Select(props)}
            {type==='ta' && Ta(props)}
            {type==='textarea' && Ta(props)}
            {type==='editor' && Editor(props)}
            {info && <Form.Text>{info}</Form.Text>}
        </Form.Group>
    );

}

const Checkbox = ({label, onChange, inline, ...props}) => {

    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.checked);
        }
    }

    const style = inline ? {display:'inline', width:'auto'} : {};

    return (<Form.Check type={'checkbox'} label={label} {...props} onChange={handleOnChange} style={style} />);
}

const Text = ({value, onChange, size, ...props}) => {

    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.value);
        }
    }

    return (
        <Form.Control type="text" value={value} onChange={handleOnChange} size={size || 'sm'} autoComplete="off" {...props} />
    );
}

const Password = ({...props}) => {
    return <Text type="password" {...props} />
}

const Number = ({...props}) => {
    return <Text type="number" {...props} />
}

const Select = ({value, onChange, opts, size, inline, ...props}) => {
    
    opts = utils.toArray(opts);

    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.value);
        }
    }

    if(utils.isNullish(value)) {
        value = '';
    }

    const style = inline ? {display:'inline', width:'auto'} : {};

    return (
        <Form.Select size={size || 'sm'} onChange={handleOnChange} value={value} style={style} {...props}>{opts.map((opt, i)=>(<option value={opt.value} key={i}>{opt.text}</option>))}</Form.Select>
    );
}

const Ta = ({value, onChange, ...props}) => {
    const handleOnChange = (e) => {
        if(onChange) {
            onChange(e.target.value);
        }
    }

    return (
        <textarea style={{minHeight:'300px'}} className="form-control form-control-sm" onChange={handleOnChange} value={value} {...props}></textarea>
    );
}

// https://www.tiny.cloud/docs/integrations/react/

const Editor = ({value, onChange, ...props}) => {
    
    const editorRef = useRef(null);

    const handleOnChange = (e) => {
        if(onChange) {
            onChange(editorRef.current.getContent());
        }
    }

    return (
        <Tinymce 
            onInit={(evt, editor) => editorRef.current = editor} 
            initialValue={value} 
            onChange={handleOnChange}
            init={{
                height: 500,
                // menubar: true,
                menubar: 'edit view insert format tools table tc help',
                plugins: 'code image link',
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor underline | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | image link |removeformat code'
            }}
            />
    );
}

const Input = {
    Checkbox,
    Fields,
    Group,
    Text,
    Select,
    Ta,
    Password,
    Editor,
    Number,
};

export default Input;