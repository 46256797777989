import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Row, Col, Card } from 'react-bootstrap';
import { API } from 'aws-amplify';

import { ItpList, ItpEdit } from '../../comps/ItpData';
import TabsPanel from '../../comps/TabsPanel';
import Btn from '../../comps/Btn';
import Input from '../../comps/Input';
import Table from '../../comps/Table';
import Icon from '../../comps/Icon';

import useMe from '../../hooks/useMe';
import useToast from '../../hooks/useToast';

import utils from '../../core/utils';
import itp from '../../core/itp';

import dayjs from 'dayjs';

const languageFields = utils.getFields(itp.languages);

const editFields = utils.getFields([
    'name', 'type', 'spec',
]);

export const Tag = () => {

    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { isAdmin, getClient } = useMe();
    const [working, setWorking] = useState(false);
    const [tag, setTag] = useState({});
    const { addToast } = useToast();
    const { tagId } = useParams();
    const client = getClient();
    // const clientId = searchParams.get('clientId');
    const clientId = client.id;
    const isAdd = tagId === '0';
    
    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Tag`);
        if(!isAdd) {
            await loadTag();
        }
    }

    const loadTag = async() => {
        setWorking(true);

        try {
            const tag = await API.get('api', `/tags/${tagId}?clientId=${clientId}`);
            
            const dateKeys = ['createdAt', 'updatedAt'];
            for(const dateKey of dateKeys) {
                const date = dayjs(tag[dateKey]);
                tag[dateKey+'Text'] = date.format(itp.settings.dateTimeFormat)+' ('+date.fromNow()+')';
            }
            
            setTag(tag);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const setTagValue = (key, value) => {
        setTag({...tag, [key]:value});
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/tags`));
    }

    const onSave = async() => {
        setWorking(true);

        if(isAdd) {
            try {

                const newTag = await API.post('api', `/tags?clientId=${clientId}`, {body:tag});
                navigate(itp.getRouterPath(`/tags/${newTag.id}`));
                addToast(`Saved tag`);
            }
            catch(error) {
                console.dir(error);
                addToast(error?.error || error, {type:'error'});
            }
        }
        else {
            try {
                await API.put('api', `/tags/${tagId}`, {body:tag});
                addToast(`Saved Tag`);
            }
            catch(error) {
                addToast(error, {type:'error'});
            }
        }

        setWorking(false);
    }

    const onRemove = async() => {
        if(!window.confirm(`Remove Tag?`)) { return; }
        setWorking(true);

        try {
            await API.del('api', `/tags/${tagId}?clientId=${clientId}`);
            navigate(itp.getRouterPath(`/tags`));
            addToast(`Removed Tag`);
        }
        catch(error) {
            console.dir(error);
            addToast(error?.error || error, {type:'error'});
        }
        setWorking(false);
    }

    useEffect(()=>{ init(); }, []);

    const tabs = [
        {
            title:`Tag Details - ${tag.name}`,
            iconName: 'Tags',
            component:(<Input.Fields fields={editFields} data={tag} onChange={(key, value)=>{ setTagValue(key, value); }} />),
        },
        {
            title:'Languages',
            iconName: 'Translate',
            component:(<Input.Fields fields={languageFields} data={tag} onChange={(key, value)=>{ setTagValue(key, value); }} />),
        },
    ];

    const header = (
        <>
            {/* <Btn text="Back to Products" iconName={'back'} onClick={onBack} />{' '}
            <Btn text="Save" iconName={'save'} onClick={onSave} variant={'primary'} /> */}
        </>
    );

    const classes = [];
    if(working) {
        classes.push('working');
    }

    return (
        <div className={classes.join(' ')}>
            <Row>
            <Col sm="8">
                <TabsPanel tabs={tabs} header={header} />
            </Col>
            <Col sm="4">
                <Card>
                <Card.Header>
                    <div className='float-end'>
                        <Btn text="Back to Tags" iconName={'back'} onClick={onBack} />{' '}
                        <Btn text={`Remove Tag`} variant={'danger'} iconName={'close'} onClick={onRemove} />{' '}
                        <Btn text="Save" iconName={'save'} onClick={onSave} variant={'success'} />
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* <div align="center">
                        <Icon name="UniversalAccess" size={200} />
                    </div>
                    <hr /> */}
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Created At</td>
                                <td>{tag.createdAtText}</td>
                            </tr>
                            <tr>
                                <td>Updated At</td>
                                <td>{tag.updatedAtText}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card.Body>
                </Card>
            </Col>
            </Row>
        </div>
    );
}


const listFields = utils.getFields([
    'name', 'type', 'spec',
]);

export const Tags = () => {

    const navigate = useNavigate();
    const { getClient, isAdmin } = useMe();
    const client = getClient();
    const [tags, setTags] = useState([]);
    const [working, setWorking] = useState();
    const { addToast } = useToast();

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Tags`);
        await loadTags();
    }

    const loadTags = async() => {
        setWorking(true);

        let tags = [];
        try {
            tags = await API.get('api', `/tags?clientId=${client.id}`);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        for(const t of tags) {
            t.keywords = Object.values(t).join(' ').toLowerCase();
        }

        setTags(tags);

        setWorking(false);
    }

    const onAction = (tag) => {
        navigate(itp.getRouterPath(`/tags/${tag.id}`));
    }

    const onAdd = () => {
        navigate(itp.getRouterPath(`/tags/0`));
    }

    useEffect(()=>{ init(); }, []);

    const classes = [];
    if(working) {
        classes.push('working');
    }

    const tabs = [
        {
            title: `Tags (${tags.length})`, 
            iconName: 'Tags', 
            component: <Table fields={listFields} data={tags} onAction={(tag)=>{ onAction(tag); }} actionText="Edit" placeholder={`Filter Tags`} download={true} header={<Btn text="Reload" iconName={'reload'} onClick={loadTags} />} />
        }
    ];

    return (
        <div className={classes.join(' ')}>
            <TabsPanel tabs={tabs} header={isAdmin() ? <Btn text="Add Tag" onClick={onAdd} /> : null} />
        </div>
    );

}