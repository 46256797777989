import { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { API } from 'aws-amplify';

import itp from '../../core/itp';

import useToast from '../../hooks/useToast';

import TabsPanel from '../../comps/TabsPanel';
import Input from '../../comps/Input';
import Btn from '../../comps/Btn';

import utils from '../../core/utils';
import Table from '../../comps/Table';

const fields = utils.getFields([
    {key:'offline', type:'select', label:'Offline', opts:[{value:'no', text:'No'}, {value:'yes', text:'Yes'}]},
    'message',
    'version',
]);

const eventFields = utils.getFields(['type', 'data', 'createdAt']);

export const Platform = () => {

    const { addToast } = useToast();
    const [working, setWorking] = useState(false);
    const [config, setConfig] = useState({});
    const [events, setEvents] = useState([]);
    const [errors, setErrors] = useState([]);

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Platform`);
        await loadConfig();
        await loadEvents();
    }

    const loadConfig = async() => {
        setWorking(true);

        try {
            const data = await API.get('api', '/config');
            const config = utils.toObject(data);
            config.offline = config.offline === false ? 'no' : 'yes';
            setConfig(config);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const loadEvents = async() => {
        setWorking(true);

        try {
            const events = await API.get('api', `/events?userId=1`);
            setEvents(events);
            const errors = await API.get('api', `/events?userId=2`);
            setErrors(errors);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onSave = async(values) => {
        values = utils.toObject(values);
        setWorking(true);
        const payload = utils.copyObject({...config, ...values});
        if(payload.offline) {
            payload.offline = payload.offline === 'no' ? false : true;
        }

        try {
            await API.put('api', '/config', {body:payload});
        }
        catch(error) {
            addToast(error, {type:'error'});
            setWorking(false);
            return;
        }

        addToast('Config saved');
        setWorking(false);
    }

    const bumpPatch = async() => {
        const ps = config.version.split('.');
        const patchKey = ps.length-1;
        const patch = Number(ps[patchKey]);
        ps[patchKey] = patch + 1;
        const version = ps.join('.');
        setConfig({...config, version});
        await onSave({version});
    }

    useEffect(()=>{ init(); }, []);

    const classes = [];
    if(working) {
        classes.push('working');
    }

    const tabs = [
        {   title: 'Config',
            iconName: 'Gear',
            component:<Input.Fields fields={fields} data={config} onChange={(key, value)=>{ setConfig({...config, [key]:value}); }} />,
        },
        {
            title: 'Events',
            component:(<Table data={events} fields={eventFields} />),
        },
        {
            title: 'Errors',
            component:(<Table data={errors} fields={eventFields} />),
        },
    ];

    return (
        <div className={classes.join(' ')}>
            <Row>
                <Col sm="7">
                    <TabsPanel tabs={tabs} />
                </Col>
                <Col sm="5">
                    <Card>
                        <Card.Header>
                            <div className='float-end'>
                                <Btn text="Reload" iconName={'reload'} variant='secondary' onClick={loadConfig} />{' '}
                                <Btn text="Save" iconName={'save'} variant='success' onClick={onSave} />
                            </div>
                            Quick Actions
                        </Card.Header>
                        <Card.Body>
                            <Btn text="Bump patch version" onClick={bumpPatch} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );

}