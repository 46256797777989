import { NavLink } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap';

export default function NavbarComponent({brand, navItems, homeUrl}) {
    navItems = navItems || [];
    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="drop-shadow">
            <Container fluid>
                <Navbar.Brand>{brand}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {navItems.length===0 ? null : 
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {navItems.map((c, i)=>{
                                return (<Nav.Link key={i} as={NavLink} to={c.to} onClick={(e)=>{ if(c.onClick) { e.preventDefault(); c.onClick(); } }}>{c.title}</Nav.Link>);
                            })}
                        </Nav>
                    </Navbar.Collapse>
                }
            </Container>
        </Navbar>
    );

}