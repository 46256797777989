import { useEffect, useState } from "react";
import { Row, Card, Tabs, Tab, Nav, Col, Alert } from 'react-bootstrap';
import { API } from 'aws-amplify';

import utils from "../../core/utils";
import itp from "../../core/itp";

import useMe from "../../hooks/useMe";
import useToast from "../../hooks/useToast";

import Input from "../../comps/Input";
import Btn from "../../comps/Btn";

export default function Tools() {

    const [working, setWorking] = useState(false);
    const [clients, setClients] = useState([]);
    const { addToast } = useToast();

    const init = async() => {
        setWorking(true);
        const clients = await API.get('api', '/clients');
        setClients(clients);

        setWorking(false);
    }

    const chemicalsJson = async() => {
        setWorking(true);
        await API.post('api', '/process/update-chemicals-list');
        addToast('Updated Chemicals list');
        setWorking(false);
    }

    useEffect(()=>{ init(); }, []);

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    return (
        <div className={classes.join(' ')}>
            <Tabs defaultActiveKey={'clients'}>
                <Tab eventKey={'clients'} title="Clients">
                    <table className="table">
                        <tbody>
                            {clients.map((client, i)=>(
                                <tr key={i}>
                                    <td>{client.name}</td>
                                    <td>{client.id}<br />ref: {client.ref}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey={'process'} title="Process">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>Update Chemicals JSON</td>
                                <td><Btn text='Run' onClick={chemicalsJson} /></td>
                            </tr>
                        </tbody>
                    </table>
                </Tab>
            </Tabs>
        </div>
    );
}