import { useEffect, useState } from 'react';
import { Card, Tab, Nav } from 'react-bootstrap';

import Icon from './Icon';

import utils from '../core/utils';

export default function TabsPanel({tabs, header, activeTabKey, onSelect}) {

    tabs = utils.toArray(tabs);
    const defaultActiveTabKey = 0;
    const [activeEventKey, setActiveEventKey] = useState(activeTabKey || defaultActiveTabKey);

    const handleOnSelect = (activeKey) => {
        setActiveEventKey(activeKey);
        
        if(onSelect) {
            onSelect(activeKey);
        }
    }

    useEffect(()=>{ setActiveEventKey(activeTabKey || defaultActiveTabKey); }, [activeTabKey]);

    return (
        <Card>
            <Tab.Container defaultActiveKey={activeEventKey} activeKey={activeEventKey} onSelect={handleOnSelect}>
            <Card.Header>
                <div className='float-end'>
                    {header}
                </div>
                <Nav variant={'tabs'}>
                    {tabs.map((tab, i)=>{
                        return (<Nav.Link key={i} eventKey={i}>{tab.iconName && <><Icon name={tab.iconName} size={20} />{' '}</>}{tab.title}</Nav.Link>);
                    })}
                </Nav>
            </Card.Header>
            <Card.Body>
                <Tab.Content>
                    {tabs.map((tab, i)=>{
                        const Component = tab.component;
                        return (<Tab.Pane key={i} eventKey={i}>{Component}</Tab.Pane>);
                    })}
                </Tab.Content>
            </Card.Body>
            </Tab.Container>
        </Card>
    );

}