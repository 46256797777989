import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ItpList, ItpEdit } from '../../comps/ItpData';

import utils from '../../core/utils';
import itp from '../../core/itp';

const listFields = utils.getFields([
    'code', 'label', 'type',
    {key:'en', label:'English'},
]);

const editFields = utils.getFields([
    'code', 'label', 'type',
]);

export const Hazard = () => {

    const navigate = useNavigate();

    const getEditingText = (label) => {
        return `Hazard - ${label.info || label.en}`;
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/hazards`));
    }

    return (
        <ItpEdit fields={editFields} label="Hazard" labels={'Hazards'} apiUrl={'/hazards'} paramKey={'hazardId'} getEditingText={getEditingText} onBack={onBack} iconName="ExclamationCircle" languages={true} />
    );

}


export const Hazards = () => {

    const navigate = useNavigate();

    const onAction = (hazard) => {
        navigate(itp.getRouterPath(`/hazards/${hazard.id}`));
    }

    return (
        <ItpList labels="Hazards" apiUrl={'/hazards'} fields={listFields} onAction={onAction} iconName="ExclamationCircle" />
    );

}