import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ItpList, ItpEdit } from '../../comps/ItpData';

import utils from '../../core/utils';
import itp from '../../core/itp';

const listFields = utils.getFields([
    'ref', 'info', 
    {key:'en', label:'English'},
]);

const editFields = utils.getFields([
    {key:'ref', label:'Ref', info:'Used by the App as an identifier to this label. Must be unique'}, 
    {key:'info', label:'Info', info:'For internal use only'},
]);

export const Label = () => {

    const navigate = useNavigate();

    const getEditingText = (label) => {
        return `Label - ${label.info || label.en || 'New Label'}`;
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/labels`));
    }

    return (
        <ItpEdit fields={editFields} label="Label" labels={'Labels'} apiUrl={'/labels'} paramKey={'labelId'} getEditingText={getEditingText} onBack={onBack} iconName="Tag" languages={true} canAdd={true} canRemove={true} />
    );

}


export const Labels = () => {

    const navigate = useNavigate();

    const onAction = (label) => {
        navigate(itp.getRouterPath(`/labels/${label.id}`));
    }

    const onAdd = () => {
        navigate(itp.getRouterPath(`/labels/0`));
    }

    return (
        <ItpList labels="Labels" apiUrl={'/labels'} fields={listFields} onAction={onAction} iconName="Tags" onAdd={onAdd} />
    );

}