import { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { Users } from './Users';
import { WhiteBlackList } from './WhiteBlackList';

import TabsPanel from '../../comps/TabsPanel';

import utils from '../../core/utils';

const filterUsers = (users) => {
    users = users.filter(u=>u.confirmed === false);

    return users;
}

export default function Dashboard() {
    
    const tabs = [
        {
            title:'Users to Confirm',
            component:(<Users filterUsers={filterUsers} download={false} showTabs={false} />),
        },
        {
            title:'Manage White/Black Lists',
            component:(<WhiteBlackList />),
        },
    ];

    return (
        <div className="fade-in">
            <TabsPanel tabs={tabs} />
        </div>
    );
}