import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';

import { ItpList, ItpEdit } from '../../comps/ItpData';
import TabsPanel from '../../comps/TabsPanel';
import Table from '../../comps/Table';
import Btn from '../../comps/Btn';

import utils from '../../core/utils';
import itp from '../../core/itp';

import useMe from '../../hooks/useMe';
import useToast from '../../hooks/useToast';

const fabricRefOpts = [
    {value:'', text:'None'},
    // {value:'01', text:'01'}, 
    {value:'01B', text:'01B'}, 
    {value:'01C', text:'01C'}, 
    // {value:'02', text:'02'}, 
    {value:'03', text:'03'}, 
    {value:'04', text:'04'}, 
    {value:'04A', text:'04A'}, 
    {value:'04B', text:'04B'}, 
    {value:'05', text:'05'}, 
    // {value:'01B', text:'03'}, 
];

const listFields = utils.getFields([
    'ref', 'info', 'name', 'en',
]);

const editFields = utils.getFields([
    // {key:'ref', label:'Ref', info:"If layers are present then Calculation Engine will use these before attempting to use Ref value. Eg: 01, 03, 04, 05B", type:'select', opts:fabricRefOpts}, 
    {key:'ref', label:'Ref', info:"If layers are present then Calculation Engine will use these before attempting to use Ref. Example values: 01, 03, 04, 05B", type:'text'}, 
    {key:'info', label:'Info', info:'For internal use only'}, 'name',
]);

export const Fabric = () => {

    const navigate = useNavigate();

    const getEditingText = (fabric) => {
        return `Fabric - ${fabric?.name || 'New Fabric'}`;
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/fabrics`));
    }

    return (
        <ItpEdit url="/fabrics" fields={editFields} isFabric={true} canRemove={true} label="Fabric" labels={'Fabrics'} apiUrl={`/fabrics`} paramKey={'fabricId'} languages={true} canAdd={true} getEditingText={getEditingText} onBack={onBack} iconName="Stack" />
    );

}


export const Fabrics = () => {

    const navigate = useNavigate();
    const { getClient } = useMe();
    const client = getClient();
    const [fabrics, setFabrics] = useState([]);
    const [working, setWorking] = useState();
    const { addToast } = useToast();

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Fabrics`);
        await loadFabrics();
    }

    const loadFabrics = async() => {
        setWorking(true);

        let fabrics = [];
        try {
            fabrics = await API.get('api', `/fabrics?clientId=${client.id}`)
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        for(const fabric of fabrics) {
            fabric['keywords'] = utils.getKeywords(fabric);
        }
        setFabrics(fabrics);

        setWorking(false);
    }

    const onAction = (fabric) => {
        navigate(itp.getRouterPath(`/fabrics/${fabric.id}`));
    }

    useEffect(()=>{ init(); }, []);

    const tabs = [
        {
            title: `Fabrics`, 
            iconName: 'Stack', 
            component: <Table fields={listFields} data={fabrics} onAction={(fabric)=>{ onAction(fabric); }} actionText="Edit" placeholder={`Filter Fabrics`} download={true} header={<Btn text="Reload" iconName={'reload'} onClick={loadFabrics} />} />
        }
    ];

    const classes = [];
    if(working) {
        classes.push('working');
    }

    const header = (<>
        <Btn text="Manage Products" onClick={()=>{ navigate(itp.getRouterPath('/products')); }} />{' '}
        <Btn text="Add Fabric" onClick={()=>{ navigate(itp.getRouterPath('/fabrics/0')); }} />{' '}
    </>);

    return (
        <div className={classes.join(' ')}>
            <TabsPanel tabs={tabs} header={header} />
        </div>
    );

}