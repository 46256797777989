import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Row, Col, Card } from 'react-bootstrap';
import { API } from 'aws-amplify';

// import { ItpList, ItpEdit } from '../comps/ItpData';
import { WhiteBlackList } from './WhiteBlackList';
import TabsPanel from '../../comps/TabsPanel';
import Btn from '../../comps/Btn';
import Input from '../../comps/Input';
import Table from '../../comps/Table';
import Modal from '../../comps/Modal';

import useMe from '../../hooks/useMe';
import useToast from '../../hooks/useToast';

import utils from '../../core/utils';
import itp from '../../core/itp';

import dayjs from 'dayjs';

const editFields = utils.getFields(['name', 'email', 'tel', 'country', 'company', 'position', 'distributor']);
const addressFields = utils.getFields(['address1', 'address2', 'address3', 'city', 'postcode', 'country']);
const clientsFields = utils.getFields(['name', {key:'permissionsText', label:'Permissions'}]);
const eventFields = utils.getFields(['type', 'data', 'createdAt']);

export const User = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { isAdmin, isDev, getClient } = useMe();
    const client = getClient();
    const [working, setWorking] = useState(false);
    const [user, setUser] = useState({});
    const [events, setEvents] = useState([]);
    const { addToast } = useToast();
    const { userId } = useParams();
    const [userClient, setUserClient] = useState(null);
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    
    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Users`);
        await loadUser();
    }

    const loadUser = async() => {
        setWorking(true);

        try {
            const user = await API.get('api', `/users/${userId}?clientId=${client.id}`);
            
            const dateKeys = ['createdAt', 'updatedAt'];
            for(const dateKey of dateKeys) {
                const date = dayjs(user[dateKey]);
                user[dateKey+'Text'] = `${date.fromNow()} (${date.format(itp.settings.dateTimeFormat)})`;
            }
            const clients = user.clients;
            for(const c of user.clients) {
                c.permissionsText = c.permissions.join(', ');
            }
            user.clients = clients;
            user.clientName = itp.clientsById[user.clientId]?.name;
            user.advancedValue = user.advanced ? 'yes' : 'no';
            
            setUser(user);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const setUserValue = (key, value) => {
        setUser({...user, [key]:value});
    }

    const loadEvents = async() => {
        return;
        setWorking(true);

        try {
            const events = await API.get('api', `/events?userId=${user.userId}&clientId=${client.id}`);
            setEvents(events);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/users`));
    }

    const onSave = async() => {
        user.advanced = user.advancedValue === 'yes' ? true : false;
        setWorking(true);

        try {
            await API.put('api', `/users/${userId}`, {body:user});
            addToast(`User Saved`);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }
        setWorking(false);
    }

    const onDisable = async() => {
        if(!window.confirm('Disable user?')) { return; }
        setWorking(true);

        try {
            const u = await API.put('api', `/users/disable/${user.userId}?clientId=${user.clientId}`);
            setUser(u);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onEnable = async() => {
        if(!window.confirm('Enable user?')) { return; }
        setWorking(true);

        try {
            const u = await API.put('api', `/users/enable/${user.userId}?clientId=${user.clientId}`);
            setUser(u);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onConfirm = async() => {
        if(!showConfirmationMessage) {
            setShowConfirmationMessage(true);
            return;
        }
        if(!window.confirm('Confirm user?')) { return; }
        setWorking(true);

        try {
            const u = await API.put('api', `/users/confirm/${user.userId}?clientId=${user.clientId}`, {body:{message:confirmationMessage}});
            setUser(u);
            setShowConfirmationMessage(false);
            setConfirmationMessage('');
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onDelete = async() => {
        if(!window.confirm('Delete user?')) { return; }
        setWorking(true);

        try {
            await API.del('api', `/users/${user.userId}?clientId=${user.clientId}`);
            addToast('User deleted');
            onBack();
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    useEffect(()=>{ init(); }, []);
    useEffect(()=>{ loadEvents(); }, [user]);

    const tabs = [
        {
            title:`Details`,
            iconName: 'PersonFill',
            component:(<Input.Fields fields={editFields} data={user} onChange={(key, value)=>{ setUserValue(key, value); }} />),
        },
        // {
        //     title:`Address`,
        //     iconName: 'PinMap',
        //     component:(<Input.Fields fields={addressFields} data={user} onChange={(key, value)=>{ setUserValue(key, value); }} />),
        // },
        // {
        //     title: 'Events',
        //     iconName: 'ListTask',
        //     component: (<Table data={events} fields={eventFields} />),
        // },
        // {
        //     title: 'Subscription',
        //     iconName: 'CurrencyPound',
        //     component: (<div>TBC</div>),
        // },
    ];

    if(isAdmin()) {
        tabs.push({
            title: 'Clients',
            iconName: 'Link',
            component: (<Table data={user.clients} fields={clientsFields} />),
        },);
        tabs.push({
            title: 'Debug',
            iconName: 'Bug',
            component: (<div><pre>{JSON.stringify(user, null, 2)}</pre></div>),
        });
    }

    if(isDev()) {
        tabs.push({
            title: 'DEV',
            iconName: 'Flag',
            component: (<div>
                <Btn text="View in DynamoDB" onClick={()=>{ window.open(`https://eu-west-2.console.aws.amazon.com/dynamodbv2/home?region=eu-west-2#edit-item?table=itp-users-dev&itemMode=2&pk=${user.id}&sk&route=ROUTE_ITEM_EXPLORER`); }} />
            </div>),
        });
    }

    const header = (
        <>
            {/* <Btn text="Back to Products" iconName={'back'} onClick={onBack} />{' '}
            <Btn text="Save" iconName={'save'} onClick={onSave} variant={'primary'} /> */}
        </>
    );

    const classes = [];
    if(working) {
        classes.push('working');
    }

    return (
        <>
        <Modal onHide={()=>{ setShowConfirmationMessage(false); }} show={showConfirmationMessage} title={`Confirm ${user.name || user.email}`}>
            <p>Send a custom confirmation message to this user</p>
            <Input.Ta value={confirmationMessage} onChange={setConfirmationMessage} />
            <br />
            <Btn text='Send Confirmation' onClick={onConfirm} />
        </Modal>
        <Modal onHide={()=>{ setUserClient(null); }} show={userClient} title={userClient ? `Edit ${userClient.name} association` : ''}>
            <table className='table'>
                <tbody>
                    <tr>
                        <td><Btn text="Demote Admin" /></td>
                        <td>Demote admin</td>
                    </tr>
                    <tr>
                        <td><Btn text="Remove association" variant={'danger'} /></td>
                        <td>this will prevent the user logging into this Client and they will have to register again</td>
                    </tr>
                </tbody>
            </table>
        </Modal>
        <div className={classes.join(' ')}>
            <Row>
            <Col sm="8">
                <TabsPanel tabs={tabs} header={header} />
            </Col>
            <Col sm="4">
                <Card>
                <Card.Header>
                    <div className='float-end'>
                        <Btn text="Back to Users" iconName={'back'} onClick={onBack} />{' '}
                        <Btn text="Delete" iconName={'close'} variant="danger" onClick={onDelete} />{' '}
                        <Btn text="Save" iconName={'save'} onClick={onSave} variant={'success'} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Email</td>
                                <td><a href={`mailto:${user?.email}`}>{user?.email}</a></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Tel</td>
                                <td><a href={`tel:${user?.tel}`}>{user?.tel}</a></td>
                                <td></td>
                            </tr>
                            {/* <tr>
                                <td>Client Name</td>
                                <td>{user?.clientName}</td>
                                <td></td>
                            </tr> */}
                            <tr>
                                <td>Enabled</td>
                                <td>{user.enabled ? 'Yes' : 'No'}</td>
                                <td>{user.enabled ? <Btn text="Disable" onClick={onDisable} iconName="PersonDash" /> : <Btn text="Enable" onClick={onEnable} iconName="PersonCheck" />}</td>
                            </tr>
                            <tr>
                                <td>Confirmed</td>
                                <td>{user.confirmed ? 'Yes' : 'No'}</td>
                                <td>{!user.confirmed && <Btn text="Confirm" onClick={onConfirm} iconName="PersonCheckFill" />}</td>
                            </tr>
                            <tr>
                                <td>Advanced</td>
                                <td><Input.Select value={user.advancedValue} opts={[{value:'no', text:'No'}, {value:'yes', text:'Yes'}]} onChange={(value)=>{ setUserValue('advancedValue', value); }} /></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Created At</td>
                                <td>{user.createdAtText}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Updated At</td>
                                <td>{user.updatedAtText}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </Card.Body>
                </Card>
            </Col>
            </Row>
        </div>
        </>
    );
}


const listFields = utils.getFields([
    'confirmed', 'enabled', 'name', 'email', {key:'permissionsText', label:'Permissions'}, {key:'appText', label:'App Version'},
]);

export const Users = ({filterUsers, showTabs, download}) => {

    showTabs = utils.toBoolean(showTabs, true);
    download = utils.toBoolean(download, true);

    const navigate = useNavigate();
    const { getClient } = useMe();
    const client = getClient();
    const [users, setUsers] = useState({});
    const [working, setWorking] = useState();
    const [activeTab, setActiveTab] = useState(utils.getItem('users-tab'));
    const { addToast } = useToast();

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Users`);
        await loadUsers();
    }

    const loadUsers = async() => {
        setWorking(true);

        let users = [];
        try {
            users = await API.get('api', `/users?clientId=${client.id}`);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        for(const u of users) {
            const date = dayjs(u.downloadedAt);
            // u.downloadAtText = `${date.fromNow()}<br /> (${date.format(itp.settings.dateTimeFormat)})`;
            u.appText = u.platform && u.appVersion ? (<><b>{u.platform}</b> v:{u.appVersion}<br />{date.fromNow()}</>) : (<em>unknown</em>);
            u.permissionsText = u.permissions.join(', ');
            u.keywords = utils.getKeywords(u);
        }
        if(filterUsers) {
            users = filterUsers(users);
        }
        setUsers(users);

        setWorking(false);
    }

    const onAction = (user) => {
        navigate(itp.getRouterPath(`/users/${user.id}`));
    }

    const handleActiveTab = (tab) => {
        utils.setItem('users-tab', tab);
        setActiveTab(tab);
    }

    useEffect(()=>{ init(); }, []);

    const classes = [];
    if(working) {
        classes.push('working');
    }

    // const tabs = clients.map((client)=>({
    //     title: `${client.name} Users`, 
    //     iconName: 'PeopleFill', 
    //     component: <Table fields={listFields} data={utils.toArray(users[client.id])} onAction={(user)=>{ onAction(user, client); }} actionText="Edit" placeholder={`Filter Users`} download={download} header={<Btn text="Reload" iconName={'reload'} onClick={loadUsers} />} />
    // }));

    const table = (
        <div className={classes.join(' ')}>
            <Table fields={listFields} data={users} onAction={(user)=>{ onAction(user); }} actionText="Edit" placeholder={`Filter Users`} download={download} header={<Btn text="Reload" iconName={'reload'} onClick={loadUsers} />} />
        </div>
    );

    if(!showTabs) {
        return (table);
    }

    const tabs = [
        {
            title: `All Users (${users.length})`, 
            iconName: 'PeopleFill', 
            component: table,
        }
    ];


    return (
        <TabsPanel tabs={tabs} activeTabKey={activeTab} onSelect={handleActiveTab} header={<Btn text="Manage White/Black lists" onClick={()=>{ navigate(itp.getRouterPath('/lists')); }} />} />
    );

}