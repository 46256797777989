import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Row, Col, Card } from 'react-bootstrap';
import { API } from 'aws-amplify';

import { ItpList, ItpEdit } from '../../comps/ItpData';
import TabsPanel from '../../comps/TabsPanel';
import Btn from '../../comps/Btn';
import Input from '../../comps/Input';
import Table from '../../comps/Table';
import Icon from '../../comps/Icon';

import useMe from '../../hooks/useMe';
import useToast from '../../hooks/useToast';

import utils from '../../core/utils';
import itp from '../../core/itp';

import dayjs from 'dayjs';


export const Content = () => {

    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { isAdmin, getClient } = useMe();
    const [working, setWorking] = useState(false);
    const [content, setContent] = useState({});
    const [editFields, setEditFields] = useState([]);
    const { addToast } = useToast();
    const { contentId } = useParams();
    const client = getClient();
    // const clientId = searchParams.get('clientId');
    const clientId = client.id;
    
    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Content`);
        await loadContent();
    }

    const loadContent = async() => {
        setWorking(true);

        try {
            const content = await API.get('api', `/content/${clientId}/${contentId}`);
            const fields = utils.getFields(content.meta.fields);
            // for(const field of fields) {
            //     if(field.key === 'content') { field.type = 'editor'; }
            // }
            setEditFields(fields);
            setContent(content);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const setContentValue = (key, value) => {
        setContent({...content, [key]:value});
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/content`));
    }

    const onSave = async() => {
        setWorking(true);

        try {
            await API.put('api', `/content/${clientId}/${contentId}?clientId=${clientId}`, {body:content});
            addToast(`Saved Content`);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }
        setWorking(false);
    }

    useEffect(()=>{ init(); }, []);

    const tabs = [
        {
            title: content.title || content.id,
            iconName: 'UniversalAccess',
            component:(<Input.Fields fields={editFields} data={content} onChange={(key, value)=>{ setContentValue(key, value); }} />),
        },
    ];

    const header = (
        <>
            <Btn text="Back to Content" iconName={'back'} onClick={onBack} />{' '}
            <Btn text="Save" iconName={'save'} onClick={onSave} variant={'success'} />
        </>
    );

    const classes = [];
    if(working) {
        classes.push('working');
    }

    return (
        <div className={classes.join(' ')}>
            <TabsPanel tabs={tabs} header={header} />
        </div>
    );
}


const listFields = utils.getFields([
    'slug', 'title', 'language',
]);

const languagesByKey = {};
for(const l of itp.languages) {
    languagesByKey[l.key] = l.label;
}

export const Contents = () => {

    const navigate = useNavigate();
    const { getClient } = useMe();
    const [content, setContent] = useState([]);
    const [working, setWorking] = useState();
    const { addToast } = useToast();
    const client = getClient();

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Content`);
        await loadContent();
    }

    const loadContent = async() => {
        setWorking(true);

        try {
            const content = await API.get('api', `/content/${client.id}`);
            for(const c of content) {
                if(c.language) {
                    c.language = languagesByKey[c.language] || c.language;
                }
            }
            setContent(content);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onAction = (content) => {
        navigate(itp.getRouterPath(`/content/${content.id}?clientId=${client.id}`));
    }

    useEffect(()=>{ init(); }, []);

    const classes = [];
    if(working) {
        classes.push('working');
    }

    const tabs = [
        {
            title:'Content',
            component:(<Table download={false} fields={listFields} data={content} onAction={(content)=>{ onAction(content); }} actionText="Edit" placeholder={`Filter Content`} header={<Btn text="Reload" iconName={'reload'} onClick={loadContent} />} />),
        }
    ];

    return (
        <div className={classes.join(' ')}>
            <TabsPanel tabs={tabs} />
        </div>
    );

}