import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Row, Tabs, Tab, Col, Alert, Spinner } from 'react-bootstrap';

import Input from '../Input';
import Btn from '../Btn';

import utils from '../../core/utils';

export function SignOut() {

    const init = async() => {
        console.log('signout.init');
        await Auth.signOut();
    }

    useEffect(()=>{ init(); }, []);

    return (
        <div align="center">
            <Spinner />
            <p align="center">Signing out...</p>
        </div>
    );
}

export function SignIn() {
    const [working, setWorking] = useState(false);
    const [message, setMessage] = useState('');
    const [values, setValues] = useState({email:'', password:'', code:''});
    const [codeType, setCodeType] = useState(null);
    const [authUser, setAuthUser] = useState(null);

    const setValue = (key, value) => {
        setValues({...values, [key]:value});
    }

    const canSignIn = () => {
        return values.email && values.password;
    }

    const onSignIn = async() => {
        setMessage('');
        setWorking(true);

        let user;
        try {
            user = await Auth.signIn(String(values.email).toLowerCase(), values.password);
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        if(user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
            setCodeType('SOFTWARE_TOKEN_MFA');
            setAuthUser(user);
        }

        setWorking(false);
    }

    const onValidate = async() => {
        setMessage('');
        setWorking(true);

        let res;
        try {
            res = await Auth.confirmSignIn(authUser, values.code, 'SOFTWARE_TOKEN_MFA');
        }
        catch(error) {
            setMessage(error.message || error);
            setWorking(false);
            return;
        }

        setWorking(false);
    }

    const onBack = () => {
        setMessage('');
        setValue('code', '');
        setCodeType(null);
        setAuthUser(null);
    }

    useEffect(()=>{
        const handleKeyUpEvent = async(e)=>{
            if(!['Enter', 'NumpadEnter'].includes(e.code)) { return; }
            if(codeType) { await onValidate(); }
            else { if(canSignIn()) { await onSignIn(); } }
        };
        
        document.addEventListener('keyup', handleKeyUpEvent);
        return () => { document.removeEventListener('keyup', handleKeyUpEvent); }
    }, [values, codeType]);

    if(codeType) {
        return (<>
            One-time passcode
            <Input.Text placeholder="eg 135790" value={values.code} onChange={(value)=>{ setValue('code', value); }} autoFocus />
            <br />
            <Btn text="Validate" onClick={onValidate} working={working} /> <Btn variant={'link'} text="back to Sign In" onClick={onBack} />
            <br /><br />
            {message && <Alert variant='danger'>{message}</Alert>}
        </>);
    }

    return (
        <>
            Email
            <Input.Text placeholder="email@domain.com" value={values.email} onChange={(value)=>{ setValue('email', value); }} autoFocus autoCapitalize="off" />
            <br />
            Password
            <Input.Password value={values.password} onChange={(value)=>{ setValue('password', value); }} />
            <br />
            <Btn text="Sign In" onClick={onSignIn} working={working} disabled={!canSignIn()} />
            <br /><br />
            {message && <Alert variant='danger'>{message}</Alert>}
        </>
    );
}

export function SignUp({fields}) {

    fields = utils.toArray(fields);

    const defaultValues = {email:'', password:'', confirmPassword:'', code:''};
    for(const field of fields) {
        defaultValues[field.key] = field?.value || '';
    }

    const standardKeys = ['email', 'password', 'confirmPassword', 'code'];

    const [working, setWorking] = useState(false);
    const [message, setMessage] = useState('');
    const [values, setValues] = useState(defaultValues);
    const [confirmation, setConfirmation] = useState(null);

    const resetValues = () => {
        const rvalues = {};
        for(const field of fields) {
            rvalues[field.key] = field?.value || '';
        }
        for(const key of standardKeys) {
            rvalues[key] = '';
        }

        setValues({...values, ...rvalues});
    }

    const setValue = (key, value) => {
        setValues({...values, [key]:value});
    }

    const canSignUp = () => {
        return values.email && values.password && values.confirmPassword;
    }

    const onSignUp = async() => {

        setMessage('');
        if(!values.password || !values.confirmPassword) {
            setMessage('Password or Confirm Password missing');
            return;
        }
        if(values.password !== values.confirmPassword) {
            setMessage('Password and Confirm Password must match');
            return;
        }

        setWorking(true);

        const attrs = {};
        for(const field of fields) {
            const key = field.key;
            if(standardKeys.includes(key)) {
                continue;
            }

            attrs[`custom:${key}`] = values[key];
        }

        let res;
        try {
            res = await Auth.signUp({username:String(values.email).toLowerCase(), password:values.password, attributes:attrs});
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        if(res?.UserConfirmed) {
            setWorking(false);
            return;
        }

        if(!res?.codeDeliveryDetails) {
            setMessage('Thanks for signing up, we will notify you when your account has been confirmed.');
            resetValues();
        }
        else {
            setConfirmation(res.codeDeliveryDetails);
        }

        setWorking(false);
    }

    const onConfirm = async() => {
        setWorking(true);

        let res;
        try {
            res = await Auth.confirmSignUp(values.email, values.code);
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        try {
            await Auth.signIn(values.email, values.password);
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        setWorking(false);
    }

    const onBack = () => {
        setConfirmation(null);
    }

    if(confirmation) {
        return (<>
            Confirmation Code
            <Input.Text placeholder="eg 135790" value={values.code} onChange={(value)=>{ setValue('code', value); }} autoFocus />
            <br />
            <Btn text="Confirm" onClick={onConfirm} working={working} /> <Btn variant={'link'} text="back to Sign Up" onClick={onBack} />
            <br /><br />
            {message && <Alert variant='danger'>{message}</Alert>}
        </>);
    }

    return (
        <>
            Email
            <Input.Text placeholder="email@domain.com" value={values.email} onChange={(value)=>{ setValue('email', value); }} autoFocus autoCapitalize="off" />
            <br />
            Create Password
            <Input.Password value={values.password} onChange={(value)=>{ setValue('password', value); }} />
            <small>should be minimum 8 characters, containing 1 number and 1 uppercase</small>
            <br /><br />
            Confirm Password
            <Input.Password value={values.confirmPassword} onChange={(value)=>{ setValue('confirmPassword', value); }} />
            <br />
            {fields.length > 0 && <hr />}
            {fields.map((field, i)=>(
                <span key={i}>
                {field.label}
                {field.type === 'text' && <Input.Text value={values[field.key]} onChange={(value)=>{ setValue(field.key, value); }} />}
                {field.type === 'select' && <Input.Select value={values[field.key]} opts={field.opts} onChange={(value)=>{ setValue(field.key, value); }} />}
                <br />
                </span>
            ))}
            <Btn text="Sign Up" onClick={onSignUp} working={working} disabled={!canSignUp()} />
            <br /><br />
            {message && <Alert variant='danger'>{message}</Alert>}
        </>
    );
}

export function Reset() {
    const [working, setWorking] = useState(false);
    const [message, setMessage] = useState('');
    const [values, setValues] = useState({email:'', password:'', confirmPassword:'', code:''});
    const [showPasswords, setShowPasswords] = useState(false);
    const [showCode, setShowCode] = useState(false);

    const setValue = (key, value) => {
        setValues({...values, [key]:value});
    }

    const sendCode = async() => {
        setMessage('');
        if(!values.password || !values.confirmPassword) {
            setMessage('Password or Confirm Password missing');
            return;
        }
        if(values.password !== values.confirmPassword) {
            setMessage('Password and Confirm Password must match');
            return;
        }

        setWorking(true);

        let res;
        try {
            res = await Auth.forgotPassword(String(values.email).toLowerCase());
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        // console.log(res);
        setShowCode(true);
        setWorking(false);
    }

    const verifyCode = async() => {
        setMessage('');
        setWorking(true);

        let res;
        try {
            res = await Auth.forgotPasswordSubmit(String(values.email).toLowerCase(), values.code, values.password);
        }
        catch(error) {
            setMessage(error.message);
            setWorking(false);
            return;
        }

        // console.log(res);

        setWorking(false);
    }

    return (
        <>
            Email
            <Input.Text placeholder="email@domain.com" value={values.email} onChange={(value)=>{ setValue('email', value); }} autoFocus autoCapitalize="off" />
            <br />
            <Btn text="Set Password" onClick={()=>{ setShowPasswords(true); }} working={working} disabled={showPasswords} />
            <br />
            {showPasswords && <>
                <br />
                New Password
                <Input.Password value={values.password} onChange={(value)=>{ setValue('password', value); }} />
                <br />
                Confirm New Password
                <Input.Password value={values.confirmPassword} onChange={(value)=>{ setValue('confirmPassword', value); }} />
                <br />
                <p>Click here to send a verification code to the email address above</p>
                <Btn text="Send Code" onClick={sendCode} working={working} disabled={working} />
                <br />
            </>}
            {showCode && <>
                <br />
                Enter received verification code below, then click confirm
                <Input.Text placeholder="verification code" value={values.code} onChange={(value)=>{ setValue('code', value); }} />
                <br />
                <Btn text="Verify Code" onClick={verifyCode} working={working} disabled={working} />
            </>}
            
            <br /><br />
            {message && <Alert variant='danger'>{message}</Alert>}
        </>
    );
}

export function SignUpInReset({signIn, signUp, reset}) {

    signIn = utils.toObject(signIn);
    signIn = {show:true, ...signIn};
    
    signUp = utils.toObject(signUp);
    signUp = {show:true, ...signUp};
    
    reset = utils.toObject(reset);
    reset = {show:true, ...reset};

    const defaultActiveKey = signIn.show ? 'signin' : (signUp.show ? 'signup' : 'reset');

    return (
        <Row>
            <Col sm="3"></Col>
            <Col sm="6">
                <Tabs defaultActiveKey={defaultActiveKey}>
                    {signIn.show && <Tab eventKey='signin' title="Sign In">
                        <SignIn {...signIn} />
                    </Tab>}
                    {signUp.show && <Tab eventKey='signup' title="Sign Up">
                        <SignUp {...signUp} />
                    </Tab>}
                    {reset.show && <Tab eventKey='reset' title='Reset Password'>
                        <Reset {...reset} />
                    </Tab>}
                </Tabs>
            </Col>
            <Col sm="3"></Col>
        </Row>
    );

}