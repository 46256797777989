import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Row, Col, Card, Alert } from 'react-bootstrap';
import { API } from 'aws-amplify';

import { ItpList, ItpEdit } from '../../comps/ItpData';
import TabsPanel from '../../comps/TabsPanel';
import Btn from '../../comps/Btn';
import Input from '../../comps/Input';
import Table from '../../comps/Table';
import Icon from '../../comps/Icon';

import useMe from '../../hooks/useMe';
import useToast from '../../hooks/useToast';

import utils from '../../core/utils';
import itp from '../../core/itp';

import dayjs from 'dayjs';

const boolOpts = [
    {value:'no', text:'No'},
    {value:'yes', text:'Yes'},
];

const generalFields = utils.getFields([
    'label', 
    {key:'cas', label:'CAS'}, 
    {key:'ec', label:'EC'}, 'climit', 'smiles',
]);

const pacFields = utils.getFields([
    {key:'pac1', label:'PAC 1'},
    {key:'pac2', label:'PAC 2'},
    {key:'pac3', label:'PAC 3'},
    {key:'cofcCarcRep', type:'select', opts:boolOpts, label:'Affects respiratory'}, 
    {key:'cofcSkin', type:'select', opts:boolOpts, label:'Affects skin'},
]);

let propsFields = [];
for(const orig in itp.chemicalPropKeys) {
    const key = itp.chemicalPropKeys[orig];
    propsFields.push(key);
}
propsFields = utils.getFields(propsFields);

const boolYesNo = (value) => {
    if(value === false) { return 'no'; }
    if(value === true) { return 'yes'; }
    if(value === 'yes') { return true; }
    if(value === 'no') { return false; }
}

export const Chemical = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { isAdmin } = useMe();
    const [working, setWorking] = useState(false);
    const [chemical, setChemical] = useState({});
    const [hazards, setHazards] = useState({});
    const [hcodes, setHcodes] = useState([]);
    const [syns, setSyns] = useState([]);
    const [props, setProps] = useState([]);
    const [newSyn, setNewSyn] = useState('');

    const { addToast } = useToast();
    const { chemicalId } = useParams();
    
    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Chemicals`);
        await loadChemical();
    }

    const loadChemical = async() => {
        setWorking(true);

        try {
            const chemical = await API.get('api', `/chemicals/${chemicalId}`);
            
            const dateKeys = ['createdAt', 'updatedAt'];
            for(const dateKey of dateKeys) {
                const date = dayjs(chemical[dateKey]);
                chemical[dateKey+'Text'] = date.format(itp.settings.dateTimeFormat)+' ('+date.fromNow()+')';
            }
            chemical.cofcCarcRep = boolYesNo(chemical.cofcCarcRep);
            chemical.cofcSkin = boolYesNo(chemical.cofcSkin);
            chemical.hcodes = utils.toArray(chemical.hcodes);
            chemical.synonyms = utils.toArray(chemical.synonyms);
            chemical.props = utils.toObject(chemical.props);
            
            setProps(chemical.props);
            setSyns(chemical.synonyms);
            setHcodes(chemical.hcodes);
            setChemical(chemical);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const loadHazards = async() => {
        setWorking(true);

        try {
            const hazards = await API.get('api', `/hazards`);
            const types = {};
            for(const h of hazards) {
                const type = h.type;
                if(!types[type]) { types[type] = []; }
                types[type].push(h);
            }
            for(const type in types) {
                types[type].sort((a, b)=>a.en < b.en ? -1 : 1);
            }
            setHazards(types);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const setChemicalValue = (key, value) => {
        setChemical({...chemical, [key]:value});
    }

    const setPropValue = (key, value) => {
        setProps({...props, [key]:value});
    }

    const onBack = () => {
        navigate(itp.getRouterPath(`/chemicals`));
    }

    const onSave = async() => {
        // setWorking(true);

        chemical.cofcCarcRep = boolYesNo(chemical.cofcCarcRep);
        chemical.cofcSkin = boolYesNo(chemical.cofcSkin);
        chemical.hcodes = hcodes;

        console.log(chemical);
        window.alert('This function currently under development');

        return;

        try {
            await API.put('api', `/chemicals/${chemicalId}`, {body:chemical});
            addToast(`Saved Product`);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }
        setWorking(false);
    }

    const handleHcode = (hcode, checked) => {
        if(checked) {
            if(!hcodes.includes(hcode)) {
                const copy = utils.copyObject(hcodes);
                copy.push(hcode);
                setHcodes(copy);
            }
        }
        else {
            if(hcodes.includes(hcode)) {
                const copy = utils.copyObject(hcodes);
                const index = copy.findIndex(id=>id===hcode);
                copy.splice(index, 1);
                setHcodes(copy);
            }
        }
    }

    const setSynValue = (index, value) => {
        const copy = utils.copyObject(syns);
        if(!copy[index]) { return; }
        copy[index] = value;
        setSyns(copy);
    }

    const removeSynValue = (index) => {
        const copy = utils.copyObject(syns);
        if(!copy[index]) { return; }
        copy.splice(index, 1);
        setSyns(copy);
    }

    const addSyn = () => {
        if(newSyn.length===0) { return; }
        const copy = utils.copyObject(syns);
        copy.push(newSyn);
        setNewSyn('');
        setSyns(copy);
    }
  
    useEffect(()=>{ init(); }, []);
    useEffect(()=>{ loadHazards(); }, [chemical]);

    const tabs = [
        {
            title:`${chemical.label} - Details`,
            component:(<Input.Fields fields={generalFields} data={chemical} onChange={(key, value)=>{ setChemicalValue(key, value); }} />),
        },
        {
            title: 'PAC',
            iconName: 'Stoplights',
            component:(<Input.Fields fields={pacFields} data={chemical} onChange={(key, value)=>{ setChemicalValue(key, value); }}  />)
        },
        {
            title: 'Hazards',
            iconName: 'ExclamationTriangle',
            component:(<Tabs defaultActiveKey={0}>{Object.keys(hazards).map((type, i)=>(
                <Tab key={i} eventKey={i} title={utils.textToTitle(type)}>
                    {hazards[type].map((hazard, i)=>(<Input.Checkbox key={i} checked={hcodes.includes(hazard.code)} label={hazard?.en} onChange={(checked)=>{ handleHcode(hazard.code, checked); }} />))}
                </Tab>))}</Tabs>),
        },
        {
            title: 'Props',
            iconName: 'Sliders2Vertical',
            component:(
                <>
                <Alert>Editing of these values is currently prohibited</Alert>
                <table className='table'>
                    <tbody>
                        {propsFields.map((field, i)=>(<tr key={i}>
                            <td>{field.label}</td>
                            <td><Input.Text value={props[field.key]} onChange={(value)=>{ setPropValue(field.key, value); }} disabled={true} /></td>
                        </tr>))}
                    </tbody>
                </table>
                </>
            ),
        },
        {
            title: 'Synonyms',
            iconName: 'NodePlus',
            component: (
                <table className='table'>
                    <tbody>
                        {syns.map((syn, i)=>(
                            <tr key={i}>
                                <td><Input.Text value={syn} onChange={(value)=>{ setSynValue(i, value); }} /></td>
                                <td><Btn iconName={'close'} onClick={()=>{ removeSynValue(i); }} /></td>
                            </tr>
                        ))}
                        <tr>
                            <td><Input.Text value={newSyn} onChange={setNewSyn} placeholder="Add synonym" /></td>
                            <td><Btn text="Add" onClick={addSyn} /></td>
                        </tr>
                    </tbody>
                </table>
            ),
        },
    ];

    const header = (
        <>
            {/* <Btn text="Back to Products" iconName={'back'} onClick={onBack} />{' '}
            <Btn text="Save" iconName={'save'} onClick={onSave} variant={'primary'} /> */}
        </>
    );

    const classes = [];
    if(working) {
        classes.push('working');
    }

    return (
        <div className={classes.join(' ')}>
            <Row>
            <Col sm="8">
                <TabsPanel tabs={tabs} header={header} />
            </Col>
            <Col sm="4">
                <Card>
                <Card.Header>
                    <div className='float-end'>
                        <Btn text="Back to Chemicals" iconName={'back'} onClick={onBack} />{' '}
                        <Btn text="Save" iconName={'save'} onClick={onSave} variant={'success'} />
                    </div>
                    Info
                </Card.Header>
                <Card.Body>
                    <div align="center">
                        {/* <Icon name="UniversalAccess" size={200} /> */}
                    </div>
                    {/* <hr /> */}
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td>Can Calc</td>
                                <td>{utils.renderValue(utils.toBoolean(chemical.canCalc))}</td>
                            </tr>
                            <tr>
                                <td>Chemical Warfare</td>
                                <td>{utils.renderValue(utils.toBoolean(chemical.isChemicalWarfare))}</td>
                            </tr>
                            <tr>
                                <td>Has Salt Warning</td>
                                <td>{utils.renderValue(utils.toBoolean(chemical.hasSaltWarning))}</td>
                            </tr>
                            <tr>
                                <td>Is Too Reactive</td>
                                <td>{utils.renderValue(utils.toBoolean(chemical.isTooReactive))}</td>
                            </tr>
                            <tr>
                                <td>Is Very Reactive</td>
                                <td>{utils.renderValue(utils.toBoolean(chemical.isVeryReactive))}</td>
                            </tr>
                            <tr>
                                <td>Created At</td>
                                <td>{chemical.createdAtText}</td>
                            </tr>
                            <tr>
                                <td>Updated At</td>
                                <td>{chemical.updatedAtText}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card.Body>
                </Card>
            </Col>
            </Row>
        </div>
    );
}


const listFields = utils.getFields([
    'cas', 'label',
]);

let filterTimer;

export const Chemicals = ({showTabs, download, onAction, actionText}) => {

    showTabs = utils.toBoolean(showTabs, true);
    download = utils.toBoolean(download, true);
    actionText = actionText || 'Edit';

    const navigate = useNavigate();
    const [chemicals, setChemicals] = useState([]);
    const [working, setWorking] = useState();
    const [keywords, setKeywords] = useState('');
    const { addToast } = useToast();

    const init = async() => {
        utils.setWindowTitle(`${itp.settings.brandName} - Chemicals`);
        await loadChemicals();
    }

    const loadChemicals = async() => {
        setWorking(true);

        try {
            const chemicals = await API.get('api', `/chemicals/list?keywords=${keywords}`);
            setChemicals(chemicals);
        }
        catch(error) {
            addToast(error, {type:'error'});
        }

        setWorking(false);
    }

    const onChemicalAction = (chemical) => {
        if(onAction) {
            onAction(chemical);
            return;
        }
        navigate(itp.getRouterPath(`/chemicals/${chemical.id}`));
    }

    const onFilter = (value) => {
        setKeywords(value);
    }

    useEffect(()=>{ init(); }, []);
    useEffect(()=>{ 
        if(filterTimer) { clearTimeout(filterTimer); }
        filterTimer = setTimeout(async()=>{
            await loadChemicals();
        }, 300);
     }, [keywords]);

     const classes = [];
    if(working) {
        classes.push('working');
    }

    const table = (
        <div className={classes.join(' ')}>
            <Table fields={listFields} data={chemicals} onAction={(chemical)=>{ onChemicalAction(chemical); }} actionText={actionText} onFilter={onFilter} placeholder={`Start typing to filter Chemicals`} download={download} header={<Btn text="Reload" iconName={'reload'} onClick={loadChemicals} />} />
        </div>
    );


     if(!showTabs) {
        return (table);
     }

    const tabs = [{
        title: `Chemicals`, 
        iconName: 'CardList', 
        component: table,
    }];

    return (
        <TabsPanel tabs={tabs} />
    );

}