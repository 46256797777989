import { Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

export default function useHub() {

    const [lastEvent, setLastEvent] = useState({channel:'', event:'', data:''});

    useEffect(()=>{
        const listener = Hub.listen(/.*/, async(e)=>{
            const { payload, channel } = e;
            const { event, data } = payload;

            setLastEvent({
                event, 
                channel,
                data,
            });
        });
        
        // dont unsubscrive as there will be nothing listening
        // return listener;
    });

    const dispatch = (channel, opts) => {
        if(typeof opts === 'string') { opts = {event:opts}; }
        const defs = {message:opts.event, data:{}};
        setTimeout(()=>{
            Hub.dispatch(channel, {...defs, ...opts});
        }, 100);
    }

    const {data, channel, event } = lastEvent;

    return {
        data, 
        channel,
        event,
        dispatch,
    };

}