import { useEffect, useState } from "react";
import { Row, Card, Tabs, Tab, Nav, Col, Alert } from 'react-bootstrap';
import { API } from 'aws-amplify';

import utils from "../../core/utils";
import itp from "../../core/itp";

import useMe from "../../hooks/useMe";
import useToast from "../../hooks/useToast";

import Input from "../../comps/Input";
import Btn from "../../comps/Btn";

import { Chemicals } from "./Chemicals";

const layerFields = utils.getFields([
    {key:'t', label:'T'}, 
    {key:'dd', label:'dD'}, 
    {key:'dp', label:'dP'}, 
    {key:'dh', label:'dH'}, 
    {key:'d', label:'D'}
]);

const layerCompositions = [0,1,2,3,4,5,6,7,8,9];

const defaultCalcs = {chemicalId:null, chemicalText:null, expTime:600, expArea:100, suitTemp:35, airTemp:18, airChanges:0, leakageRate:0, protectionFactor:0, volume:120, weight:90,};

const ChemicalText = ({calcs, onReset}) => {
    return (
        <h5>Chemical: {calcs.chemicalId ? (<>{calcs.chemicalText} <Btn text="" iconName={'close'} variant={'secondary-outline'} onClick={onReset} /></>) : (<>Non selected</>) }</h5>
    );
}

export function FabricLayers({fabric, onChange}) {

    // const defaultValues = {};
    // for(const layer of layers) { defaultValues[layer] = ''; }
    const { getClient, isAdmin } = useMe();
    const { addToast } = useToast();
    const client = getClient();

    const [layers, setLayers] = useState({});
    const [fabrics, setFabrics] = useState([]);
    const [fabricOpts, setFabricOpts] = useState([]);
    const [fabricOpt, setFabricOpt] = useState('');
    const [working, setWorking] = useState(false);
    const [calcs, setCalcs] = useState(defaultCalcs);
    const [res, setRes] = useState({});

    const init = async() => {
        setLayers(utils.toObject(fabric.layers));
        await loadFabrics();
    }

    const loadFabrics = async() => {
        const fabrics = await API.get('api', `/fabrics?clientId=${client.id}`);
        fabrics.sort((a, b)=>a.name>b.name ? 1 : -1);
        setFabrics(fabrics);
        const fabricOpts = [{value:'', text:''}];
        for(const fabric of fabrics) {
            fabricOpts.push({value:fabric.id, text:`${fabric.name} - ${fabric.info}`});
        }
        setFabricOpts(fabricOpts);
    }

    const setValue = (key, index, value) => {
        // const comp = {...layers[key], [index]:Number(value)}; 
        if(!Array.isArray(layers[key])) { layers[key] = []; }
        const comp = [...layers[key]];
        // comp[index] = Number(value);
        comp[index] = value;
        const newValues = {...layers, [key]:comp};
        // console.log({newValues});
        setLayers({...newValues});
        onChange(newValues);
    }

    const setCalcsValue = (key, value) => {
        setCalcs({...calcs, [key]:value});
    }

    const runCalc = async() => {
        
        const params = {
            ...calcs,
            layers,
            fabricId: fabric.id,
        }

        const checkKeys = {
            chemicalId:'No Chemical selected', 
            expArea: 'Set exposed area',
            expTime: 'Set exposure time',
        };

        const errors = [];
        for(const key in checkKeys) {
            if(!params[key]) {
                errors.push(checkKeys[key]);
            }
        }

        if(errors.length !== 0) {
            window.alert(`Errors:\n${errors.join('\n')}`);
            return;
        }

        console.log(params);
        // setRes({});
        
        setWorking(true);

        let res;
        try {
            res = await API.post('api', `/permasure/calc?clientId=${client.id}`, {body:params});
        }
        catch(error) {
            addToast(error?.error || error, {type:'error'});
        }

        const expKeys = ['permeationrate', 'totalpermeated', 'leakageAmount', 'vapourPressure', 'totalIngress'];
        for(const key of expKeys) {
            res[key] = Number(res[key]).toFixed(4);
        }
        
        res.pac1time = Number(res.pac1time).toFixed(4);
        res.pac2time = Number(res.pac2time).toFixed(4);
        res.pac3time = Number(res.pac3time).toFixed(4);

        setRes(res);

        setWorking(false);
    }

    const clearLayer = (index) => {
        const newLayers = {...layers};
        for(const key in newLayers) {
            newLayers[key].splice(index, 1);
        }
        setLayers(newLayers);
    }

    const setProtectionFactor = (protectionFactor) => {
        const rate = utils.toNumber(protectionFactor);
        const leakageRate = Number(rate !== 0 ? 100/rate : 0).toFixed(6);
        setCalcs({...calcs, protectionFactor, leakageRate});
    }

    const importFabricLayer = () => {
        if(!window.confirm('Import Fabric Layers?')) { return; }
        const fabric = fabrics.find(f=>f.id===fabricOpt);
        if(!fabric) { window.alert('Could not find Fabric to import'); return; }
        const layers = fabric.layers;
        if(Object.keys(layers).length === 0) { window.alert('Fabric has no layer values to import'); return; }
        setLayers(layers);
    }

    useEffect(()=>{ init(); }, [fabric]);
    // useEffect(()=>{ setRes({}); }, [calcs, layers]);

    const classes = ['fade-in'];
    if(working) {
        classes.push('working');
    }

    return (
        <>
        {/* <Alert>This feature is still under development</Alert> */}

        <div className={classes.join(' ')}>

        <div className="float-end">
            <table>
                <tbody>
                    <tr>
                        <td>Import Fabric Layers</td>
                        <td></td>
                        <td><Input.Select opts={fabricOpts} value={fabricOpt} onChange={setFabricOpt} /></td>
                        <td></td>
                        <td><Btn text="Import" onClick={importFabricLayer} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h5>Layers</h5>
        <br />

        <table className="table">
            <thead>
                <tr>
                    <th>Composition</th>
                    {layerCompositions.map((index, i)=>(
                        <th key={i}><Btn text="clear" onClick={()=>{ clearLayer(i) }} /></th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {layerFields.map((field, i)=>{
                    const key = field.key;
                    const data = utils.toArray(layers[key]);
                    return (
                        <tr key={i}>
                            <td>{field.label}</td>
                            {layerCompositions.map((index, i)=>(<td key={i}><Input.Text value={utils.isNullish(data[index]) ? '' : data[index]} onChange={(value)=>{ setValue(key, index, value); }} /></td>))}
                        </tr>
                    );
                })}
                {/* {layers.map((layer, i)=>(<tr key={i}>
                    <td>{layer}</td>
                    <td><Input.Text value={values[layer]} onChange={(value)=>{ setValues({...values, [layer]:value}); }} disabled={true} /></td>
                </tr>))} */}
            </tbody>
        </table>

        <p>&nbsp;</p>
        
        <h5>Test Fabric Layers</h5>
        <br />
        
        <Tabs defaultActiveKey={'chemical'}>
            <Tab eventKey={'chemical'} title="1. Chemical">
                <ChemicalText calcs={calcs} onReset={()=>{ setCalcs({...calcs, chemicalId:null, chemicalText:null}); }} />
                {/* <div style={{maxHeight:300, overflow:'auto'}}> */}
                <Chemicals actionText={'Select'} onAction={(chemical)=>{ setCalcs({...calcs, chemicalId:chemical.id, chemicalText:`${chemical.label} (${chemical.cas})`}); }} showTabs={false} download={false} />
                {/* </div> */}
            </Tab>
            <Tab eventKey={'params'} title="2. Parameters">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>Suit Temp (°C)</td>
                            <td><Input.Text value={calcs.suitTemp} onChange={(suitTemp)=>{ setCalcsValue('suitTemp', suitTemp); }} /></td>
                        </tr>
                        <tr>
                            <td>Chemical Temp (°C)</td>
                            <td><Input.Text value={calcs.airTemp} onChange={(airTemp)=>{ setCalcsValue('airTemp', airTemp); }} /></td>
                        </tr>
                        <tr>
                            <td>Leakage Rate (%)</td>
                            <td>{calcs.leakageRate}</td>
                        </tr>
                        <tr>
                            <td>Protection Factor</td>
                            <td><Input.Text value={calcs.protectionFactor} onChange={(protectionFactor)=>{ setProtectionFactor(protectionFactor); }} /></td>
                        </tr>
                        <tr>
                            <td>Air changes /hr (l/min)</td>
                            <td><Input.Text value={calcs.airChanges} onChange={(airChanges)=>{ setCalcsValue('airChanges', airChanges); }} /></td>
                        </tr>
                        <tr>
                            <td>Suit Weight (kg)</td>
                            <td><Input.Text value={calcs.weight} onChange={(weight)=>{ setCalcsValue('weight', weight); }} /></td>
                        </tr>
                        <tr>
                            <td>Suit Volume (l)</td>
                            <td><Input.Text value={calcs.volume} onChange={(volume)=>{ setCalcsValue('volume', volume); }} /></td>
                        </tr>
                        <tr>
                            <td>Exposed Area</td>
                            <td><Input.Text value={calcs.expArea} onChange={(expArea)=>{ setCalcsValue('expArea', expArea); }} /></td>
                        </tr>
                        <tr>
                            <td>Exposed Time</td>
                            <td><Input.Text value={calcs.expTime} onChange={(expTime)=>{ setCalcsValue('expTime', expTime); }} /></td>
                        </tr>
                    </tbody>
                </table>
            </Tab>
            <Tab eventKey={'assessment'} title="3. Assessment">
                <div className="float-end">
                    <Btn text='Calc' onClick={runCalc} working={working} />
                </div>
                <ChemicalText calcs={calcs} onReset={()=>{ setCalcs({...calcs, chemicalId:null, chemicalText:null}); }} />
                <table className="table">
                    <tbody>
                        <tr>
                            <td>Permeation Rate</td>
                            <td>{res.permeationrate} (µg/cm²/min)</td>
                            <td>Total Permeated</td>
                            <td>{res.totalpermeated} (g)</td>
                        </tr>
                        <tr>
                            <td>Vapour Pressure</td>
                            <td>{res.vapourPressure} (Pa)</td>
                            <td>Leakage Amount</td>
                            <td>{res.leakageAmount} (g)</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>Total Ingress</td>
                            <td>{res.totalIngress} (g)</td>
                        </tr>
                        <tr>
                            <td>Chemical - PAC 1</td>
                            <td>{res.chemicalpac1} (min)</td>
                            <td>Assessment - PAC 1</td>
                            <td>{res.pac1time} (min)</td>
                        </tr>
                        <tr>
                            <td>Chemical - PAC 2</td>
                            <td>{res.chemicalpac2} (min)</td>
                            <td>Assessment - PAC 2</td>
                            <td>{res.pac2time} (min)</td>
                        </tr>
                        <tr>
                            <td>Chemical - PAC 3</td>
                            <td>{res.chemicalpac3} (min)</td>
                            <td>Assessment - PAC 3</td>
                            <td>{res.pac3time} (min)</td>
                        </tr>
                    </tbody>
                </table>
            </Tab>
        </Tabs>
        
        
        </div>
        </>
    );

}