import { Button, Spinner } from 'react-bootstrap';

import Icon from './Icon';

export default function Btn({text, working, disabled, children, onClick, variant, size, iconName, iconSize, ...props}) {

    size = size || 'sm';
    variant = variant || 'secondary';
    iconSize = iconSize || 16;

    const handleOnClick = (e) => {
        e.preventDefault();
        if(disabled) {
            return;
        }

        if(onClick) {
            onClick(e);
        }
    }

    switch(iconName) {
        case 'reload':
            iconName = 'BootstrapReboot';
        break;
        case 'close':
            iconName = 'XCircle';
        break;
        case 'download':
            iconName = 'CloudArrowDown';
        break;
        case 'save':
            iconName = 'CheckCircle';
        break;
        case 'back':
            iconName = 'ArrowLeftCircle';
        break;
        case 'open':
            iconName = 'BoxArrowUpRight';
        break;  
        default:

        break;
    }

    let icon = iconName && (<><Icon name={iconName} size={iconSize} />{' '}</>);
    if(working) {
        icon = (<><Spinner size='sm' />{' '}</>);
    }

    // Spinner

    return (
        <Button variant={variant} size={size} onClick={handleOnClick} disabled={disabled} {...props}>{icon}{text || children}</Button>
    );

}