import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Btn from './Btn';
import Input from './Input';

import utils from '../core/utils';

export default function Table({data, fields, actionText, onAction, onFilter, placeholder, working, header, download}) {

    data = utils.toArray(data);
    fields = utils.toArray(fields);
    download = utils.toBoolean(download, false);
    placeholder = placeholder || 'Filter data';

    const [filter, setFilter] = useState('');
    const [sortKey, setSortKey] = useState(null);
    const [classes, setClasses] = useState([]);

    if(onAction) {
        actionText = actionText || 'View';
    }
    else {
        actionText = false;
    }

    const handleAction = (data) => {
        if(onAction) {
            onAction(data);
        }
    }

    const handleSetFilter = (value) => {
        if(onFilter) {
            onFilter(value);
        }
        setFilter(value);
    }

    const getData = () => {
        if(onFilter) {
            return data;
        }

        const q = String(filter).trim().toLowerCase();
        if(!q) {
            return data;
        }
        return data.filter(c=>c.keywords.includes(q));
    }

    const sortData = () => {
        // const d = data[0];
        // if(!d[sortKey]) {
        //     return;
        // }


    }

    useEffect(()=>{ sortData(); }, [sortKey]);
    useEffect(()=>{ setClasses(working ? ['working'] : []); }, [working]);

    const filteredData = getData();

    const onDownload = () => {
        utils.downloadCsv(filteredData, 'Table-Data');
    }

    // const thStyles = {position:'sticky', top:31, zIndex:10, background:'white'};
    const thStyles = {};
    // const rowStyles = {position:'sticky', top:0, zIndex:10, backgroundColor:'white'};
    const rowStyles = {};

    return (
        <div className={classes.join(' ')}>
            <Row style={rowStyles}>
            <Col sm="7">
                <Input.Text value={filter} onChange={handleSetFilter} placeholder={placeholder} />
            </Col>
            <Col sm="5">
                {header}{' '}
                {download && <><Btn text="Download" variant="outline" onClick={onDownload} iconName="download" />{' '}</>}
            </Col>
            </Row>
            <br />
            <table className="table table-hover table-striped1" style={{backgroundColor:'white'}}>
                <thead>
                    <tr>
                    {fields.map((f, i)=>(
                        <th key={i} style={thStyles} onClick={()=>{ setSortKey(f.key); }}>{f.label}</th>
                    ))}
                    {(actionText || onAction) && <th style={thStyles}>&nbsp;</th>}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length===0 && <tr><td colSpan={fields.length}><p align="center">No data</p></td></tr>}
                    {filteredData.map((row, r)=>(
                        <tr key={r}>
                            {fields.map((f, i)=>(<td key={i}>{utils.renderValue(row[f.key])}</td>))}
                            {actionText && <td><Btn onClick={()=>{ handleAction(row); }}>{actionText}</Btn></td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

}