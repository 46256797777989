import { configureStore, createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
    name: 'data',
    initialState: {

    },
    reducers: {
        setData: (state, action) => {
          for(const key in action.payload) {
              state[key] = action.payload[key];
          }
        }
    },
});

export const { setData, } = dataSlice.actions;

export default configureStore({
  reducer: {
    data: dataSlice.reducer,
  },
});