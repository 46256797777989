import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { API } from "aws-amplify";

import useToast from "../../hooks/useToast";
import useMe from "../../hooks/useMe";

import Btn from "../../comps/Btn";
import Input from "../../comps/Input";
import TabsPanel from "../../comps/TabsPanel";

import utils from "../../core/utils";
import itp from "../../core/itp";

const trimLcValue = (value) => {
    return String(value).trim().toLowerCase().replaceAll(' ', '');
}

export function WhiteBlackList() {

    const navigate = useNavigate();

    const { getClient } = useMe();
    const { addToast } = useToast();
    const [working, setWorking] = useState();
    const [black, setBlack] = useState([]);
    const [white, setWhite] = useState([]);
    const [addWhite, setAddWhite] = useState('');
    const [addBlack, setAddBlack] = useState('');

    const client = getClient();

    const init = async() => {
        await loadData();
    }

    const loadData = async() => {
        setWorking(true);
        try {
            const black = await API.get('api', `/users/blacklist?clientId=${client.id}`);
            setBlack(black);
            const white = await API.get('api', `/users/whitelist?clientId=${client.id}`);
            setWhite(white);
        }
        catch(error) {
            throw error;
        }

        setWorking(false);
    }

    const onSave = async() => {
        setWorking(true);
        try {
            await API.put('api', '/users/blacklist', {body:black});
            await API.put('api', '/users/whitelist', {body:white});
            addToast('Lists saved');
        }
        catch(error) {
            throw error;
        }

        setWorking(false);
    }

    const remove = (list, i) => {
        const isWhite = list === 'white';
        const copy = utils.copyObject(isWhite ? white : black);
        copy.splice(i, 1);
        if(isWhite) { setWhite(copy); }
        else { setBlack(copy); }
    }

    const add = (list) => {
        const isWhite = list === 'white';
        const copy = utils.copyObject(isWhite ? white : black);
        if(isWhite) { copy.push(trimLcValue(addWhite)); setWhite([...new Set(copy)]); setAddWhite(''); }
        else { copy.push(trimLcValue(addBlack)); setBlack([...new Set(copy)]); setAddBlack(''); }
    }

    useEffect(()=>{ init(); }, []);

    const classes = [];
    if(working) {
        classes.push('working');
    }

    const tabs = [
        {
            title: 'Whitelist',
            component:(
                <>
                <p>These domains will be allowed to register</p>
                <hr />
                <table className="table">
                    <tbody>
                        {white.map((data, i)=>(<tr key={i}>
                            <td>{data}</td>
                            <td><Btn variant="outline" iconName={'close'} text="Remove" onClick={()=>{ remove('white', i); }} /></td>
                        </tr>))}
                        <tr>
                            <td><Input.Text value={addWhite} onChange={setAddWhite} placeholder="eg gmail.com" /></td>
                            <td><Btn variant={'secondary'} text="Add" onClick={()=>{ add('white'); }} /></td>
                        </tr>
                    </tbody>
                </table>
                </>
            ),
        },
        {
            title: 'Blacklist',
            component:(
                <>
                <p>These domains will NOT be allowed to register</p>
                <hr />
                <table className="table">
                    <tbody>
                        {black.map((data, i)=>(<tr key={i}>
                            <td>{data}</td>
                            <td><Btn variant="outline" iconName={'close'} text="Remove" onClick={()=>{ remove('black', i); }} /></td>
                        </tr>))}
                        <tr>
                            <td><Input.Text value={addBlack} onChange={setAddBlack} placeholder="eg gmail.com" /></td>
                            <td><Btn variant={'secondary'} text="Add" onClick={()=>{ add('black'); }} /></td>
                        </tr>
                    </tbody>
                </table>
                </>
            ),
        }
    ];

    const header = (<><Btn  text="Manage Users"onClick={()=>{ navigate(itp.getRouterPath('/users')); }} />{' '}<Btn text="Save lists" variant="success" onClick={onSave} iconName='save' /></>);

    return (
        <div className={classes.join(' ')}>
            <TabsPanel tabs={tabs} header={header} />
        </div>
    );
}