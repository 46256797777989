import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Dropdown } from 'react-bootstrap';

import Navbar from '../../comps/Navbar';
import Modal from '../../comps/Modal';

import utils from "../../core/utils";
import itp from "../../core/itp";

import useMe from "../../hooks/useMe";
import useHub from "../../hooks/useHub";

import Tools from "./Tools";

const ClientSelector = ({clients, client}) => {
    const { setClient } = useMe();
    clients = utils.toArray(clients);
    
    if(clients.length < 2) {
        return client?.name;
    }

    const selectClient = (c) => {
        setClient(c.id);
        setTimeout(()=>{ window.location.reload(); }, 300);
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                {client?.name} (change)
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {clients.map((c, i)=>(<Dropdown.Item key={i} href="#" onClick={(e)=>{ e.preventDefault(); selectClient(c); }}>{c?.name}</Dropdown.Item>))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default function Layout() {

    const hub = useHub();
    
    const { me, isAdmin, isManager, getClients, getClient } = useMe();
    const navigate = useNavigate();
    const [navItems, setNavItems] = useState([]);
    const [clientRef, setClientRef] = useState(utils.getItem('signup.client.ref'));
    const [signedIn, setSignedIn] = useState(false);
    const [showTools, setShowTools] = useState(false);
    const clients = getClients();
    const client = getClient();

    const setupNav = async() => {
        if(signedIn) {
            const isAnAdmin = isAdmin();
            const components = itp.getComponents({isAdmin:isAnAdmin, isManager:isManager()});
            if(isAnAdmin) {
                components.push({title:'[Tools]', to:'/tools', onClick:()=>{ setShowTools(true); }});
            }
            setNavItems(components);
        }
        else {
            setNavItems([]);
        }
    }

    const handleHubEvent = async(e) => {
        // const { event } = e?.payload;
        // console.log('[HubEvent]', {e, event});

        // console.log('LAYOUT.handleHubEvent', hub.channel, hub.event);

        if(hub.channel === itp.events.CHANNEL) {
            switch(hub.event) {
            case itp.events.types.SIGN_IN:
                setSignedIn(true);
            break;
            case itp.events.types.SIGN_OUT:
                setSignedIn(false);
            break;
            default:
                
            break;
            }
        }
    }

    useEffect(()=>{ handleHubEvent(); }, [hub.event, hub.channel]);
    useEffect(()=>{ setupNav(); }, [signedIn]);

    return (
        <>
        <Modal show={showTools} size="xl" title="Tools" onHide={()=>{ setShowTools(false); }}>
            {showTools && <Tools />}
        </Modal>
        <Navbar brand={<ClientSelector clients={getClients()} client={getClient()} />} navItems={navItems} />
        <Container fluid>
            <div className="content-container">
                <Outlet />
            </div>
            <div align="center" style={{marginTop:20}}>
                {signedIn && <>Logged in as '{me?.email}' {isAdmin()? (<small>[<b>admin</b>]</small>) :''} {isManager()?(<small>[<b>manager</b>]</small>):''} {client?.name} {isAdmin()?(<small>[<b>{client.id}</b>]</small>):``}</>}
                {/* {!me && <details><summary>Client</summary>{Object.keys(itp.clients).map((key, i)=>(<p key={i} style={clientRef === key ? {color:'red', textDecoration:'underline'} : {textDecoration:'underline'}} onClick={()=>{ setClientRef(key); }}>{itp.clients[key].name}</p>))}</details>} */}
            </div>
        </Container>
        </>
    );

}