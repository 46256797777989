import { Modal } from 'react-bootstrap';

export default function ModalComponent({show, onHide, title, children, size}) {

    return (
        <Modal show={show} onHide={onHide} size={size || 'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );

}